/**
 *
 */

.footer {
  background: var(--xs-footer-background);
  border: 0;
  color: var(--xs-footer-color);
  border-radius: 0;
  margin: 0;

  @media (min-width: 768px) {
    background: var(--sm-footer-background);
  }

  @media (max-width: 991px) {
    text-align: center;
  }

  @media (min-width: 992px) {
    background: var(--md-footer-background);
  }

  .container {
    @media (min-width: 768px) {
      background: var(--sm-footer-container-background);
    }

    @media (min-width: 992px) {
      background: var(--md-footer-container-background);
      padding: 0 15px;
    }
  }

  .container:first-child {
    @media (min-width: 992px) {
      padding-top: 15px;
    }
  }

  .container:last-child {
    padding-bottom: 15px;

    @media (min-width: 768px) {
      padding-bottom: 25px;
    }

    @media (min-width: 992px) {
      padding-bottom: 45px;
    }
  }

  .nav > li {
    display: inline-block;
    white-space: nowrap;
    float: none;

    a {
      color: var(--xs-link-color);
      padding: 10px 8px;
    }

    &.divider {
      display: inline;
      white-space: normal;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAPCAYAAAAlH6X5AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyppVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyRThDNjA4NzAzNzgxMUU3OTNGNEFEM0EwMUY0MzIzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyRThDNjA4ODAzNzgxMUU3OTNGNEFEM0EwMUY0MzIzQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjJFOEM2MDg1MDM3ODExRTc5M0Y0QUQzQTAxRjQzMjNCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjJFOEM2MDg2MDM3ODExRTc5M0Y0QUQzQTAxRjQzMjNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+E9zeogAAABpJREFUeNpiWLly5QEmBgYGexDBQAFxECDAANs3A9hrz7agAAAAAElFTkSuQmCC');
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  .navbar-nav {
    @media (max-width: 767px) {
      margin-top: 11px;
    }

    @media (max-width: 991px) {
      margin-top: 0;
      float: none !important;
    }

    @media (min-width: 992px) {
      margin-top: 12px;
      clear: left;
    }

    > li {
      a {
        @media (min-width: 768px) {
          text-decoration: none;
        }

        @media (min-width: 992px) {
          padding: 9px;
        }
      }

      &:first-child {
        a {
          @media (min-width: 992px) {
            padding-left: 0;
          }
        }
      }
    }
  }

  .col-md-5 {
    .navbar-nav {
      @media (max-width: 767px) {
        margin-top: 5px;
      }
    }
  }

  .navbar-right {
    margin-bottom: 0;

    @media (min-width: 768px) and (max-width: 991px) {
      margin: 15px 0 6px;
      float: none;
    }

    @media (min-width: 992px) {
      padding: 9px 0 0 11px;
    }

    @media (min-width: 1200px) {
      padding: 9px 0 0;
    }

    > li {
      a {
        padding: 14px 16px;
        color: var(--xs-footer-color);
        font-size: 34px;

        @media (min-width: 768px) {
          padding: 14px 22px;
        }

        @media (min-width: 992px) {
          padding: 14px 19px;
        }
      }
    }
  }

  .navbar-text {
    font-size: 10px;
    line-height: 16px;
    margin-top: 12px;

    @media (max-width: 991px) {
      float: none;
      margin-top: 15px;
    }

    @media (min-width: 992px) {
      margin: 13px 0;
    }
  }
}
