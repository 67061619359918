ol.breadcrumb {
  background: none;
  font-family: "MarkPro";
  line-height: 22px;
  border-radius: 0;
  padding: 22px 15px 15px;

  @media (max-width: 767px) {
    margin: 0 7px 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
    padding: 40px 15px 5px;
    font-size: 16px;

    a {
      text-decoration: none;
    }
  }

  @media (min-width: 992px) {
    padding-top: 20px;
    padding-left: 0;
    float: left;
  }

  @media (min-width: 1200px) {
    padding-top: 30px;
  }

  > li::before {
    color: var(--xs-footer-color);
    padding: 0 2px 0 5px;
    content: none;
    display: none;
  }

  > li {
    span,
    a {
      padding: 10px 8px;
    }

    &:first-child {
      span,
      a {
        padding-left: 0;
      }
    }

    &.divider {
      display: inline;
      white-space: normal;
      opacity: 0.6;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAALCAYAAABYpyyrAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyppVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpDMUNDNUJBRjA1MjMxMUU3OTNGNEFEM0EwMUY0MzIzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDMUNDNUJCMDA1MjMxMUU3OTNGNEFEM0EwMUY0MzIzQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkMxQ0M1QkFEMDUyMzExRTc5M0Y0QUQzQTAxRjQzMjNCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkMxQ0M1QkFFMDUyMzExRTc5M0Y0QUQzQTAxRjQzMjNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+qsYlAAAAAEJJREFUeNpiYkAAAyAWQOIzfAAJMEE5CkD8ACQIE3AA4gsgBhOS/gPI+i9ABVEMZIBpgetHFjiALIBh4AdkFwIEGAD/0AsvzVcRcQAAAABJRU5ErkJggg==');
      background-position: left center;
      background-repeat: no-repeat;
    }
  }
}
