/**
 *
 */

.header {
  background: var(--xs-header-background);
  border: 0;
  margin-bottom: 0;

  @media (min-width: 768px) {
    border-radius: 0;
    background: var(--sm-header-background);
  }

  @media (min-width: 992px) {
    background: var(--md-header-background);
  }

  .container {
    padding-bottom: 12px;

    @media (min-width: 768px) {
      background: var(--sm-header-container-background);
      padding-bottom: 0;
    }

    @media (min-width: 992px) {
      background: var(--md-header-container-background);
    }
  }

  .navbar-toggle {
    z-index: 2;
    float: left;
    border-radius: 0;
    margin: 0;
    padding: 10px 12px 11px;
    border: 0;

    .icon-bar {
      height: 3px;
      width: 25px;
    }
  }

  .navbar-header {
    @media (max-width: 991px) {
      float: none;

      @media (min-width: 768px) {
        padding: 16px 0 11px;
      }
    }

    .navbar-brand {
      background: url('/assets/dist/images/logo.svg') center center/contain no-repeat;
      height: 74px;
      width: 182px;
      text-indent: -999em;

      @media (max-width: 991px) {
        margin: 4px auto 1px;
        float: none;
        display: block;
      }

      @media (max-width: 767px) {
        max-height: 50px;
        margin: 9px auto 0;
      }

      @media (min-width: 768px) {
        width: 242px;
        height: auto;
        margin: 12px auto 11px;
      }

      @media (min-width: 992px) {
        margin: 20px 0;
      }

      @media (min-width: 1200px) {
        margin: 20px 15px;
      }
    }
  }

  .navbar-right {
    padding: 52px 30px 0 0;

    .navbar-text {
      font-weight: 600;
    }

    p {
      margin: 0;
    }

    .navbar-nav > li {
      display: inline-block;
      white-space: nowrap;

      > a {
        padding: 8px 12px;
        font-size: 15px;
        color: var(--md-header-link-color);
        text-decoration: none;
      }

      &:last-child > a {
        padding-right: 2px;
      }

      &.divider {
        display: inline;
        min-width: 2px;
        min-height: 36px;
        white-space: normal;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAPCAYAAAAlH6X5AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyppVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyRThDNjA4NzAzNzgxMUU3OTNGNEFEM0EwMUY0MzIzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyRThDNjA4ODAzNzgxMUU3OTNGNEFEM0EwMUY0MzIzQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjJFOEM2MDg1MDM3ODExRTc5M0Y0QUQzQTAxRjQzMjNCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjJFOEM2MDg2MDM3ODExRTc5M0Y0QUQzQTAxRjQzMjNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+E9zeogAAABpJREFUeNpiWLly5QEmBgYGexDBQAFxECDAANs3A9hrz7agAAAAAElFTkSuQmCC');
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
}
