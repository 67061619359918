/**
 *
 */

@media (max-width: 991px) {
  .navbar-header {
    float: none;
  }

  .navbar-left,
  .navbar-right {
    float: none !important;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }

  .navbar-nav > li {
    float: none;
  }

  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .collapse.in {
    display: block !important;
  }
}

.header {
  .container {
    position: relative;
  }

  .navbar-toggle {
    padding: 11px 12px 12px 13px;
    background: var(--xs-menu-background);
    z-index: 11;

    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
    }

    &:focus {
      background: var(--xs-menu-background);
    }

    &.collapsed {
      background: var(--xs-hamburger-background);
    }

    .icon-bar {
      background: var(--xs-hamburger-color);
      width: 25px;
    }
  }

  .close {
    display: none;
  }
}

.navbar-collapse {
  background: var(--xs-menu-background);
  border: 0;
  border-radius: 0;

  @media (max-width: 768px) {
    box-shadow: 0 8px 5px -2px rgba(0, 0, 0, 0.2);
    padding: 41px 0 0;
  }

  @media (max-width: 991px) {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    background: transparent;
    padding: 0;
    margin: 0;
    overflow: hidden;
    box-shadow: none;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
    padding: 0;
    background: var(--md-menu-background);
  }

  .container {
    padding: 0;

    @media (min-width: 992px) {
      background: var(--md-menu-container-background);
    }
  }

  .navbar-nav {
    margin: 0 auto;

    @media (min-width: 768px) and (max-width: 991px) {
      position: absolute;
      top: 0;
      background: var(--xs-menu-background);
      padding-top: 41px;
      box-shadow: 0 8px 5px -2px rgba(0, 0, 0, 0.2);
    }

    .caret {
      @media (min-width: 992px) {
        font-size: 14px;
        line-height: 46px;
      }

      &::before {
        content: "\f078";
      }
    }

    .open {
      background: var(--xs-menu-item-background);

      .caret::before {
        content: "\f077";
      }

      .dropdown-menu {
        text-align: center;
        padding: 0;

        .divider {
          background: var(--xs-menu-background);
          margin: 0;
          height: 9px;
        }
      }
    }

    .open > a,
    .open > a:focus,
    .open .dropdown-menu > li > a,
    & > li > a,
    & > li > a:focus {
      background: var(--xs-menu-item-background);
      border-top: 1px solid var(--xs-hamburger-color);
      color: var(--xs-menu-item-color);
      font-size: 16px;
      font-weight: 600;
      line-height: 59px;
      padding: 0;
      text-decoration: none;

      @media (min-width: 768px) {
        padding: 0 55px;
      }

      @media (min-width: 992px) {
        padding: 0 35px;
        line-height: 48px;
      }

      @media (min-width: 1200px) {
        padding: 0 44px;
        line-height: 50px;
        border: 0;
      }

      &:hover {
        background: var(--xs-menu-background);
        color: var(--xs-menu-item-color);
      }
    }

    .open a:focus,
    & > li > a,
    & > li > a:focus {
      @media (min-width: 992px) {
        border-right: 1px solid rgba(255, 255, 255, 0.7);
      }
    }

    .dropdown-menu {
      @media (min-width: 768px) and (max-width: 992px) {
        width: 100%;
      }
    }

    li.close {
      display: block;
      float: none;
      line-height: initial;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0;

      @media (min-width: 768px) and (max-width: 991px) {
        z-index: 4;
      }

      > a:hover,
      > a {
        background: var(--xs-hamburger-background);
        border: 0;
        color: var(--xs-hamburger-color);
        width: 50px;
        line-height: 41px;
        text-shadow: none;
        font-size: 22px;

        &:hover {
          background: var(--xs-hamburger-background);
          color: var(--xs-hamburger-color);
        }

        @media (min-width: 768px) {
          padding: 0;
        }
      }
    }
  }
}
