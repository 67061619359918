/**
 *
 */
.cta {
  background: var(--xs-cta-background);
  color: var(--xs-cta-color);
  font-size: 18px;
  font-weight: 500;
  padding: 20px 80px;

  @media (min-width: 768px) {
    padding: 30px 0;
  }

  @media (min-width: 992px) {
    border-left: 9px solid transparent;
    padding: 50px 40px 35px;
  }

  @media (min-width: 1200px) {
    border-left-width: 18px;
    padding: 45px 70px 25px;
  }

  strong {
    font-family: "DINPro";
    font-size: 21px;
    line-height: 24px;
    font-weight: 700;
    display: block;
    margin: 14px 0 0;

    @media (min-width: 768px) {
      font-size: 28px;
      margin: 0;
    }

    @media (min-width: 992px) {
      font-size: 28px;
      line-height: 32px;
      margin: 3px 0 6px;
    }
  }

  p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;

    @media (min-width: 768px) {
      padding-top: 2px;
      font-size: 16px;
    }

    @media (min-width: 992px) {
      margin-bottom: 20px;
    }
  }

  .btn {
    background: var(--xs-button-background);
    border-radius: 4px;
    font-size: 15px;
    color: var(--primary-darkest);
    letter-spacing: 1px;
    line-height: 9px;
    padding: 22px;
    text-transform: uppercase;
    margin-top: 10px;

    @media (min-width: 768px) {
      margin: 20px 0 0;
    }

    @media (min-width: 992px) {
      margin: 30px 0 10px;
    }

    &:hover {
      background: var(--secondary-lightest);
    }
  }
}
