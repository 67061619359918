/**
 *
 */

body {
  min-width: 320px;
  font-family: OpenSans, 'Open Sans', Arial, sans-serif;
  font-size: 14px;
}

a {
  color: var(--xs-link-color);
  text-decoration: underline;

  &.dropdown-toggle {
    text-decoration: none;
  }
}

.btn {
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  padding: 1px 39px 3px;
  font-size: 16px;
  line-height: 36px;
  border-radius: 5px;
}

.btn-danger {
  background: var(--xs-button-background);
  color: var(--xs-button-color);
}

@media (max-width: 767px) {
  .container {
    > h2 {
      padding: 0;
      margin: 0;

      > a.dropdown-toggle {
        margin: 0 -15px;
        padding: 17px 15px;
      }
    }
  }

  h2 > a.dropdown-toggle {
    display: block;
    border-top: 1px solid var(--xs-drawer-color);
    background: var(--xs-drawer-background);
    color: var(--xs-drawer-color);
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    line-height: 1;

    &::after {
      content: " ";
      display: block;
      clear: both;
    }

    .collapsible-title {
      display: block;
      float: left;
      width: calc(100% - 35px);
    }

    .caret {
      float: right;
      margin-right: 10px;
    }
  }
}

.dropdown-toggle {
  .caret {
    display: inline;
    border: 0;
    min-height: 3em;
    min-width: 1em;
    background: url('/assets/dist/images/icon-up.svg') no-repeat center center;

    @media (min-width: 768px) {
      background: url('/assets/dist/images/icon-up-sm.svg') no-repeat center center;
      padding: 0 20px;
    }
  }

  &.collapsed {
    .caret {
      background: url('/assets/dist/images/icon-down.svg') no-repeat center center;

      @media (min-width: 768px) {
        background: url('/assets/dist/images/icon-down-sm.svg') no-repeat center center;
      }
    }
  }
}

/* Must be declared separately */
::-webkit-input-placeholder {
  color: var(--xs-placeholder-color);
  opacity: 1;
}

:-moz-placeholder {
  color: var(--xs-placeholder-color);
  opacity: 1;
}

::-moz-placeholder {
  color: var(--xs-placeholder-color);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--xs-placeholder-color);
  opacity: 1;
}
