/**
 * Client Branding
 *
 * To begin, pick the client primary color scheme. This usually isn't a single color,
 * but multiple shades of a single bold color.
 *
 * A secondary color needs picked as well, and this color needs to contrast the primary.
 * It is usually shades of grayscale or a muted color.
 *
 * Locate up to 5 different shades of each color scheme, and update the --primary-*
 * and --secondary-* sections below from lightest to darkest. If you do not have
 * 5 different color variations, you may need to duplicate them. Try to keep them
 * as evenly distributed as possible.
 *
 * Repeat this process for text colors, which are almost always shades of grayscale.
 *
 * The contrast color is typically a very bold color that is meant to draw the users
 * eye to a Call To Action (CTA).
 */

:root {
  /* Primary colors */
  --primary-lightest: #e6eff4;
  --primary-lighter: #2467b4;
  --primary: #2467b4;
  --primary-darker: #0059a4;
  --primary-darkest: #0059a4;

  /* Secondary colors */
  --secondary-lightest: #fff;
  --secondary-lighter: #fff;
  --secondary: #f6f7f9;
  --secondary-darker: #ebf2f6;
  --secondary-darkest: #ebf2f6;

  /* Text colors */
  --text-lightest: #fff;
  --text-lighter: #a9a9a9;
  --text: #595959;
  --text-darker: #595959;
  --text-darkest: #303030;

  /* Contrast color */
  --contrast: #fff;
}

/**
 * The following section is for modifying individual sections of the site once the
 * primary and secondary colors are in place.
 *
 * Unless the client has two primary colors, or more than 5 color variations, no
 * new colors should be defined below. References to the colors defined above should
 * be used whenever possible.
 *
 * The variables follow the naming convention:
 *
 * {{size}}-{{section}}-{{thing?}}-{{property}}
 *
 * Where {{size}} is the bootstrap width, {{section}} is a matching CSS section (see
 * `/assets/src/css/`), {{thing?}} is an option description of an element within the
 * section, and {{property}} is either "color" for the text color, or "background"
 * for the background color.
 *
 * For Sprint 1, no additional variables should be needed. For Sprint 2, additional
 * variables may be needed. They should be defined here, rather than adding color
 * codes to individual CSS files.
 */

:root {
  /* Extra Small Layout Colors */
  --xs-placeholder-color: #999;
  --xs-button-background: var(--contrast);
  --xs-button-color: var(--primary-darkest);
  --xs-content-color: var(--text-darker);
  --xs-content-heading-color: var(--text-darkest);
  --xs-cta-background: transparent;
  --xs-body-cta-background: var(--primary-darkest);
  --xs-body-cta-color: var(--text-lightest);
  --xs-cta-color: var(--text-lightest);
  --xs-drawer-background: var(--primary-darker);
  --xs-drawer-color: var(--text-lightest);
  --xs-footer-background: var(--secondary);
  --xs-footer-color: var(--text);
  --xs-hamburger-background: var(--contrast);
  --xs-hamburger-color: var(--text-lightest);
  --xs-header-background: var(--secondary-lightest);
  --xs-hero-background: var(--primary-darkest);
  --xs-hero-color: var(--text-darker);
  --xs-link-color: var(--primary-darker);
  --xs-links-color: var(--primary-darker);
  --xs-map-background: var(--primary-darkest);
  --xs-map-item-background: var(--secondary-lightest);
  --xs-map-item-color: var(--text-darker);
  --xs-map-item-strong-color: var(--text-darkest);
  --xs-map-more-background: var(--primary);
  --xs-map-more-color: var(--text-lightest);
  --xs-mapbox-background: #aecef3;
  --xs-menu-background: var(--secondary-darkest);
  --xs-menu-item-background: var(--primary-lighter);
  --xs-menu-item-color: var(--text-darkest);
  --xs-search-background: var(--secondary-darkest);
  --xs-search-button-background: var(--primary-darkest);
  --xs-search-button-color: var(--text-lightest);

  /* Small Layout Colors */
  --sm-content-background: var(--secondary);
  --sm-content-color: var(--text-darker);
  --sm-content-container-background: var(--secondary-lightest);
  --sm-header-background: var(--secondary);
  --sm-body-cta-background: var(--secondary);
  --sm-body-cta-color: var(--text-darkest);
  --sm-header-container-background: var(--xs-header-background);
  --sm-hero-background: var(--secondary);
  --sm-hero-container-background: var(--primary-darkest);
  --sm-footer-background: var(--xs-footer-background);
  --sm-footer-container-background: var(--xs-footer-background);
  --sm-links-background: var(--secondary);
  --sm-links-caret-color: var(--contrast);
  --sm-links-color: var(--primary-darker);
  --sm-links-container-background: var(--secondary-lightest);
  --sm-location-background: var(--secondary-lightest);
  --sm-map-active-background: var(--secondary-lightest);
  --sm-map-active-color: var(--text-darkest);
  --sm-map-background: var(--secondary);
  --sm-map-container-background: var(--secondary-lightest);
  --sm-map-item-background: var(--primary-lightest);
  --sm-map-name-color: var(--text-darker);
  --sm-search-background: var(--primary-lightest);
  --sm-map-anchor-background: var(--primary-lightest);
  --sm-map-anchor-color: var(--primary-darkest);
  --sm-search-color: var(--text-darker);

  /* Medium Layout Colors */
  --md-content-background: var(--secondary);
  --md-content-container-background: var(--secondary-lightest);
  --md-footer-background: var(--xs-footer-background);
  --md-footer-container-background: var(--xs-footer-background);
  --md-header-background: var(--secondary);
  --md-header-container-background: var(--xs-header-background);
  --md-header-link-color: var(--primary-darker);
  --md-hero-background: var(--secondary);
  --md-hero-color: var(--text-lightest);
  --md-hero-container-background: transparent;
  --md-links-background: var(--secondary);
  --md-links-container-background: var(--secondary-lightest);
  --md-map-background: var(--secondary);
  --md-map-item-background: var(--secondary-lightest);
  --md-menu-background: var(--primary-lighter);
  --md-menu-container-background: var(--xs-menu-item-background);
}
