/**
 *
 */

#content {
  @media (max-width: 767px) {
    padding: 3px 0 10px;
  }

  @media (min-width: 768px) {
    padding-bottom: 10px;
    font-size: 15px;
    line-height: 24px;
  }

  &.collapse {
    @media (min-width: 768px) {
      display: block;
    }
  }
}

.content {
  line-height: 21px;
  color: var(--sm-content-color);

  @media (min-width: 768px) {
    background: var(--sm-content-background);
    line-height: 24px;
  }

  @media (min-width: 992px) {
    background: var(--md-content-background);
  }

  .container {
    @media (min-width: 768px) {
      padding: 22px 24px 3px;
      background: var(--sm-content-container-background);
    }

    @media (min-width: 992px) {
      background: var(--md-content-container-background);
      padding: 17px 30px 4px;
    }

    @media (min-width: 1200px) {
      padding: 21px 30px 11px;
    }
    
    &.container-404 {
      border-top: 1px solid #c8c8c8;
    }
  }

  h1,
  h2 {
    color: var(--xs-content-heading-color);
    font-family: "DINPro";
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    padding: 18px 0 10px;

    @media (min-width: 768px) {
      margin: 0;
      padding-bottom: 20px;
      padding-top: 18px;
      line-height: 32px;
      font-size: 28px;
      font-weight: 400;
    }

    @media (min-width: 992px) {
      font-size: 28px;
      padding-bottom: 14px;
    }
  }

  h1 {
    @media (max-width: 767px) {
      padding-top: 20px;
    }
  }

  p {
    font-family: "MarkPro";

    span.btn-holder {
      display: block;
    }

    @media (min-width: 768px) {
      margin: 0;
      padding-bottom: 18px;
      line-height: 28px;
      font-size: 16px;

      img {
        float: left;
        margin-right: 30px;
      }
    }

    @media (min-width: 992px) {
      margin-bottom: 24px;
    }
  }

  .img-col {
    img {
      display: block;
    }
  }

  .btn.btn-danger {
    background: var(--primary-darker);
    color: var(--text-lightest);
    font-weight: 700;
    margin-top: 10px;
    padding: 11px 28px;
  }
}
