@font-face {
  font-family: 'DINPro';
  font-style: normal;
  font-weight: 400;
  src: local('DIN Pro'), url(/assets/dist/fonts/DINPro.woff) format('woff');
}

@font-face {
  font-family: 'DINPro';
  font-style: normal;
  font-weight: 500;
  src: local('DIN Pro Medium'), url(/assets/dist/fonts/DINPro-Medium.woff) format('woff');
}

@font-face {
  font-family: 'DINPro';
  font-style: normal;
  font-weight: 700;
  src: local('DIN Pro Bold'), url(/assets/dist/fonts/DINPro-Bold.woff) format('woff');
}

@font-face {
  font-family: 'DINPro';
  font-style: normal;
  font-stretch: condensed;
  font-weight: 700;
  src: local('DIN Pro Condensed Bold'), url(/assets/dist/fonts/DINPro-CondBold.woff) format('woff');
}

@font-face {
  font-family: 'MarkPro';
  font-style: normal;
  font-weight: 400;
  src: local('Mark Pro'), url(/assets/dist/fonts/MarkPro.woff) format('woff');
}

@font-face {
  font-family: 'MarkPro';
  font-style: normal;
  font-weight: 200;
  src: local('Mark Pro Light'), url(/assets/dist/fonts/MarkPro-Light.woff) format('woff');
}

@font-face {
  font-family: 'MarkPro';
  font-style: italic;
  font-weight: 200;
  src: local('Mark Pro Light'), url(/assets/dist/fonts/MarkPro-LightItalic.woff) format('woff');
}

@font-face {
  font-family: 'MarkPro';
  font-style: normal;
  font-weight: 300;
  src: local('Mark Pro Book'), url(/assets/dist/fonts/MarkPro-Book.woff) format('woff');
}

@font-face {
  font-family: 'MarkPro';
  font-style: normal;
  font-weight: 500;
  src: local('Mark Pro Medium'), url(/assets/dist/fonts/MarkPro-Medium.woff) format('woff');
}

@font-face {
  font-family: 'MarkPro';
  font-style: normal;
  font-weight: 700;
  src: local('Mark Pro Bold'), url(/assets/dist/fonts/MarkPro-Bold.woff) format('woff');
}
