.fa-map-marker {
  background: url('/assets/dist/images/icon-marker.svg') no-repeat center center;
  min-height: 1em;

  &::before {
    content: "";
  }
}

.fa-newspaper-o {
  background: url('/assets/dist/images/icon-info.svg') no-repeat center center;
  min-height: 1em;

  &::before {
    content: "";
  }
}

.fa-phone {
  background: url('/assets/dist/images/icon-phone.svg') no-repeat center center;
  min-height: 1em;

  &::before {
    content: "";
  }
}

.fa-search {
  background: url('/assets/dist/images/icon-zoom.svg') no-repeat center center;
  min-height: 1em;

  &::before {
    content: "";
  }
}

.fa-chevron-right {
  background: url('/assets/dist/images/icon-right.svg') no-repeat center center;
  min-height: 1em;
  min-width: 1em;

  &::before {
    content: "";
  }
}

.fa-chevron-left {
  background: url('/assets/dist/images/icon-left.svg') no-repeat center center;
  min-height: 1em;
  min-width: 1em;

  &::before {
    content: "";
  }
}
