/**
 *
 */

.hero {
  background-color: var(--xs-hero-background);
  color: var(--xs-hero-color);

  @media (min-width: 768px) {
    background-color: var(--sm-hero-background);
  }

  @media (max-width: 991px) {
    text-align: center;
  }

  @media (min-width: 992px) {
    background-color: var(--md-hero-background);
    color: var(--md-hero-color);
  }

  .container {
    background: url('/assets/dist/images/gmf-hero-bg-xs.jpg') no-repeat right top;

    @media (min-width: 768px) {
      background: url('/assets/dist/images/gmf-hero-bg-sm.jpg') no-repeat center top;
      background-color: var(--sm-hero-container-background);
    }

    @media (min-width: 992px) {
      background: url('/assets/dist/images/gmf-hero-bg-md.jpg') no-repeat center top;
      background-color: var(--md-hero-container-background);
    }

    @media (min-width: 1200px) {
      background: url('/assets/dist/images/gmf-hero-bg-lg.jpg') no-repeat center top;
      background-color: var(--md-hero-container-background);
      padding: 0;

      & > .row {
        display: table;
        width: 100%;
        margin: 0;
      }
    }
  }

  &.man {
    .container {
      background: url('/assets/dist/images/hero-man-xs.jpg') no-repeat right top;

      @media (min-width: 768px) {
        background: url('/assets/dist/images/hero-man-sm.jpg') no-repeat center top;
        background-color: var(--sm-hero-container-background);
      }

      @media (min-width: 992px) {
        background: url('/assets/dist/images/hero-man-md.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }

      @media (min-width: 1200px) {
        background: url('/assets/dist/images/hero-man-lg.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }
    }
  }

  &.woman {
    .container {
      background: url('/assets/dist/images/hero-woman-xs.jpg') no-repeat right top;

      @media (min-width: 768px) {
        background: url('/assets/dist/images/hero-woman-sm.jpg') no-repeat center top;
        background-color: var(--sm-hero-container-background);
      }

      @media (min-width: 992px) {
        background: url('/assets/dist/images/hero-woman-md.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }

      @media (min-width: 1200px) {
        background: url('/assets/dist/images/hero-woman-lg.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }
    }
  }

  &.couple {
    .container {
      background: url('/assets/dist/images/hero-couple-xs.jpg') no-repeat right top;

      @media (min-width: 768px) {
        background: url('/assets/dist/images/hero-couple-sm.jpg') no-repeat center top;
        background-color: var(--sm-hero-container-background);
      }

      @media (min-width: 992px) {
        background: url('/assets/dist/images/hero-couple-md.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }

      @media (min-width: 1200px) {
        background: url('/assets/dist/images/hero-couple-lg.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }
    }
  }

  &.family {
    .container {
      background: url('/assets/dist/images/hero-family-xs.jpg') no-repeat right top;

      @media (min-width: 768px) {
        background: url('/assets/dist/images/hero-family-sm.jpg') no-repeat center top;
        background-color: var(--sm-hero-container-background);
      }

      @media (min-width: 992px) {
        background: url('/assets/dist/images/hero-family-md.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }

      @media (min-width: 1200px) {
        background: url('/assets/dist/images/hero-family-lg.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }
    }
  }

  &.couple-outside {
    .container {
      background: url('/assets/dist/images/hero-couple-outside-xs.jpg') no-repeat right top;

      @media (min-width: 768px) {
        background: url('/assets/dist/images/hero-couple-outside-sm.jpg') no-repeat center top;
        background-color: var(--sm-hero-container-background);
      }

      @media (min-width: 992px) {
        background: url('/assets/dist/images/hero-couple-outside-md.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }

      @media (min-width: 1200px) {
        background: url('/assets/dist/images/hero-couple-outside-lg.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }
    }
  }

  &.door-handle {
    .container {
      background: url('/assets/dist/images/hero-door-handle-xs.jpg') no-repeat right top;

      @media (min-width: 768px) {
        background: url('/assets/dist/images/hero-door-handle-sm.jpg') no-repeat center top;
        background-color: var(--sm-hero-container-background);
      }

      @media (min-width: 992px) {
        background: url('/assets/dist/images/hero-door-handle-md.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }

      @media (min-width: 1200px) {
        background: url('/assets/dist/images/hero-door-handle-lg.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }
    }
  }

  &.woman-tablet {
    .container {
      background: url('/assets/dist/images/hero-woman-tablet-xs.jpg') no-repeat right top;

      @media (min-width: 768px) {
        background: url('/assets/dist/images/hero-woman-tablet-sm.jpg') no-repeat center top;
        background-color: var(--sm-hero-container-background);
      }

      @media (min-width: 992px) {
        background: url('/assets/dist/images/hero-woman-tablet-md.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }

      @media (min-width: 1200px) {
        background: url('/assets/dist/images/hero-woman-tablet-lg.jpg') no-repeat center top;
        background-color: var(--md-hero-container-background);
      }
    }
  }

  .hero-content-wrapper {
    line-height: 22px;

    a {
      color: var(--xs-hero-color);

      @media (min-width: 992px) {
        color: var(--md-hero-color);
      }
    }

    strong {
      font-size: 21px;
      line-height: 24px;
      margin: 13px 10px 12px;
      display: block;
      font-weight: 600;
      widows: 2;

      @media (min-width: 768px) {
        font-size: 26px;
        font-weight: 600;
        margin: 18px 10px;
        line-height: 36px;
      }

      @media (min-width: 992px) {
        margin: 16px 0;
      }

      @media (min-width: 1200px) {
        font-weight: bold;
        margin: 14px 0 24px;
        font-size: 36px;
        line-height: 44px;
      }
    }

    @media (min-width: 992px) {
      padding: 50px 30px 50px 50px;
    }

    @media (min-width: 1200px) {
      padding: 58px 30px;
    }
  }

  h1 {
    font-family: "DINPro";
    font-size: 22px;
    font-weight: 700;
    font-stretch: condensed;
    text-transform: uppercase;
    line-height: 1;
    margin: 23px 0 16px;

    @media (min-width: 992px) {
      font-size: 40px;
      margin-top: 16px;
    }

    @media (min-width: 1200px) {
      font-size: 50px;
    }
  }

  p {
    font-family: "MarkPro";
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    min-height: 1em;
  }
}
