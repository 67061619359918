.body-cta {
  background: var(--xs-body-cta-background);
  color: var(--xs-body-cta-color);
  padding: 50px 0;

  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;
    padding: 20px 0;
  }

  @media (min-width: 768px) {
    background: var(--sm-body-cta-background);
    color: var(--sm-body-cta-color);
  }

  .container {
    padding: 0 30px;

    @media (min-width: 320px) and (max-width: 767px) {
      padding: 0 15px;
    }
  }

  h2 {
    margin: 0 0 10px;
  }

  p {
    a {
      color: var(--xs-body-cta-color);

      @media (min-width: 768px) {
        color: var(--sm-body-cta-color);
      }
    }
  }

  .btn-danger {
    font-size: 18px;
    line-height: 21px;
    padding: 20px 28px;
    border-radius: 10px;
  }
}
