/**
 *
 */

#content-404 {
  text-align: center;
  
  @media (max-width: 767px) {
    padding: 3px 0 10px;
  }

  @media (min-width: 768px) {
    padding: 60px 0;
  }

  &.collapse {
    @media (min-width: 768px) {
      display: block;
    }
  }

   .header-404 {
    color: var(--xs-content-heading-color);
    font-family: "DINPro";
    font-size: 26px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
    padding: 18px 0 10px;

    @media (min-width: 768px) {
      margin: 0 0 30px;
      padding: 0px;
      line-height: 32px;
      font-size: 28px;
      font-weight: 600;
    }

    @media (min-width: 992px) {
      font-size: 28px;
    }
  }

  .reason-404 {
    color: var(--xs-content-color);
    font-family: "DINPro";
    font-size: 15px;
    line-height: 21px;
    margin: 25px 0;
    padding: 0;
  }
  
  .next-actions-404 {
    color: var(--xs-content-color);
    font-family: "DINPro";
    font-size: 15px;
    line-height: 21px;
    margin: 30px auto;
    padding: 0;
  } 
}