/**
 *
 */

.location.map {
  .container {
    @media (min-width: 767px) {
      padding: 25px 15px 30px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
      padding: 0 15px 30px;

      > .row {
        margin: 0;
      }
    }

    @media (min-width: 992px) {
      padding: 0 15px;
    }
  }

  #list-panel {
    padding: 12px 10px 0;

    @media (min-width: 768px) {
      background: var(--sm-location-background);
      margin: 0;
      padding: 30px;
      width: 100%;
      min-height: 400px;
    }

    @media (min-width: 992px) {
      width: 41.5%;
      margin: 0;
    }

    .place {
      cursor: auto;

      @media (max-width: 767px) {
        padding: 10px;
      }

      @media (min-width: 768px) {
        background: transparent;
        padding: 24px 15px 0;
        border: 0;
      }

      @media (min-width: 992px) {
        padding: 0;
      }

      .convenience {
        display: block;
        cursor: auto;
      }

      .name {
        @media (min-width: 768px) {
          font-size: 22px;
          padding-bottom: 6px;
          margin-bottom: 10px;
          border-bottom: 1px solid #ccc;
        }
      }

      .address {
        @media (min-width: 768px) {
          padding: 0;
        }

        > .hidden-xs {
          font-weight: 600;
          font-size: 14px;
          padding-top: 6px;
          display: inline-block;
        }

        .telephone {
          display: inline-block;
          padding-right: 10px;
          border-right: 1px solid #333;
          margin-right: 10px;
          line-height: 1;

          @media (max-width: 767px) {
            border: none;
          }
        }
      }

      .hours {
        @media (min-width: 768px) {
          padding: 0;
          margin: 20px 0 0;
        }

        strong {
          @media (min-width: 768px) {
            margin: 2px auto;
          }
        }

        .telephone {
          display: inline;
          margin-left: 5px;
          padding-left: 5px;
          border-left: 1px solid #000;
        }
      }

      .interact {
        @media (min-width: 768px) {
          padding: 18px 0 0;
        }

        @media (min-width: 992px) {
          float: none;
          text-align: left;
        }

        .btn {
          @media (min-width: 768px) {
            padding: 10px 12px;
            display: inline-block;
            margin-bottom: 30px;
          }
        }
      }
    }
    
    .dealer-website {
      background: #fff;
      padding: 20px 0 0;
      font-weight: bold;
      
      @media (max-width: 767px) {
        padding: 10px 0 0;
        text-align: center;
      }
    }

    .icons {
      background: var(--xs-map-item-background);
      margin: 0 0 15px;
      padding: 5px 20px 15px;
    }

    .telephone,
    .locality {
      @media (max-width: 767px) {
        display: inline-block;
        white-space: nowrap;
        padding: 0 8px;
      }
    }

    .telephone a {
      text-decoration: none;
    }

    .divider {
      @media (max-width: 767px) {
        display: inline;
        white-space: normal;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAPCAYAAAAlH6X5AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyppVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MzU3QjU2RjE4MTYxMUU3QjIxQjkzRjQxRDMyQkM2QiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MzU3QjU3MDE4MTYxMUU3QjIxQjkzRjQxRDMyQkM2QiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjgzNTdCNTZEMTgxNjExRTdCMjFCOTNGNDFEMzJCQzZCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjgzNTdCNTZFMTgxNjExRTdCMjFCOTNGNDFEMzJCQzZCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+44vrfgAAABpJREFUeNpi0NTUNGBiYGDoBxEMFBAXAAIMAFp2Aiek9c/1AAAAAElFTkSuQmCC');
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }

  #map-panel-holder {
    @media (min-width: 768px) {
      padding: 0;
      width: 100%;
    }

    @media (min-width: 992px) {
      width: 58.5%;
    }

    @media (min-width: 1200px) {
      width: 58.5%;
    }
  }

  #location-map {
    @media (min-width: 768px) {
      height: 308px;
    }
  }

  #location-search {
    @media (min-width: 992px) {
      padding: 21px 78px;
    }

    @media (min-width: 1200px) {
      padding: 21px 175px;
    }
  }

  .col-lg-3 {
    padding: 12px 10px 0;
  }
}
