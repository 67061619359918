/**
 *
 */

.related {
  padding-top: 10px;

  .list-group-item {
    border: 0;
    padding: 6px 15px;

    a {
      font-size: 14px;
      color: var(--xs-links-color);
      text-decoration: none;
    }
  }
}

@media (min-width: 768px) {
  .hasPOS,
  .availableFrom,
  .areaServed,
  .makesOffer,
  .containsPlace {
    background: var(--sm-links-background);

    @media (min-width: 992px) {
      background: var(--md-links-background);
    }

    h2 {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      color: var(--text-darkest);
      font-family: "DINPro";
      font-size: 28px;
      font-weight: 400;
      line-height: 32px;
      padding-top: 45px;
      padding-left: 9px;
      margin: 0 0 16px;

      .caret {
        color: var(--sm-links-caret-color);
        font-size: 20px;
      }
    }

    .container {
      padding-bottom: 20px;
      background: var(--sm-links-container-background);

      @media (min-width: 992px) {
        background: var(--md-links-container-background);
        padding: 0 30px 32px;
      }
    }

    .related {
      padding-left: 26px;

      @media (min-width: 992px) {
        padding-left: 2px;
      }

      @media (min-width: 1200px) {
        padding-left: 12px;
      }
    }

    .list-group {
      padding-top: 0;
      margin-bottom: 14px;
    }

    .list-group-item {
      background: transparent;
      font-family: "MarkPro";
      font-size: 18px;
      line-height: 32px;
      padding: 0 15px;
    }
  }
}
